export const color = {
  grid: '#e3ecf2',
  gridBg: '#f3f3f3',
  gridBorder: '#c0c0c0',
  defaultBg: 'white',
  defaultText: '#212d3b',
  headerBg: '#f2f4f6',
  headerText: '#212d3b',
  frozen: 'aliceblue',
  selectionOutline: '#e72076',
  selectionBorder: 'white',
  selectionOverlay: 'rgba(0, 0, 0, 0)',
  selectedRow: '#fad2e3',
  inputOutline: '#3b99fc',
  error: '#c0392e',
  tooltip: 'black',
  conflict: '#f2b32a',
  positive: '#3a835b',
  negative: '#d9364c',
}

export const font = {
  normal: '12px Eina01',
  mono: '12px Courier',
}

export const cellHeight = 20

// line widths
export const width = {
  grid: 1,
  gridBorder: 2,
  frozen: 4,
  outline: 2,
  padding: 1,
  mark: 7,
}

export const txtPadding = Math.round(0.25 * cellHeight)
