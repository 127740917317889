import React from 'react'
import { compareAsc, minTime } from 'date-fns'

import Table from './Table'

class TableView extends React.Component {
  state = {
    sortByColumnIndex: 0,
    sortOrder: 'desc',
  }

  sortData = (data) => {
    const { columns } = this.props
    const { sortByColumnIndex, sortOrder } = this.state

    const column = columns[sortByColumnIndex]

    let compareFn = (a, b) => a.localeCompare(b)
    if (column.isDate) {
      compareFn = (a, b) => {
        // Provides way to sort dates when dates are missing from input data.
        a = a || minTime
        b = b || minTime
        return compareAsc(a, b)
      }
    }
    if (column.numeric) {
      compareFn = (a, b) => a - b
    }

    return data.sort(
      (a, b) =>
        (sortOrder === 'asc' ? 1 : -1) * compareFn(a[column.id], b[column.id]),
    )
  }

  sortByColumn = (columnIndex) => {
    this.setState((state) => ({
      sortByColumnIndex: columnIndex,
      sortOrder:
        state.sortByColumnIndex === columnIndex && state.sortOrder === 'asc'
          ? 'desc'
          : 'asc',
    }))
  }

  render() {
    const { data, columns, renderStatusbar } = this.props
    const sortedData = this.sortData(data)

    return (
      <Table
        data={sortedData}
        columns={columns}
        renderStatusbar={renderStatusbar}
        sortingOptions={{
          columnIndex: this.state.sortByColumnIndex,
          sortOrder: this.state.sortOrder,
        }}
        sortByColumn={this.sortByColumn}
      />
    )
  }
}

export default TableView
