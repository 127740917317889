import React, { Component } from 'react'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DateRangePicker } from 'react-date-range'
import Select from 'react-select'

import { addMonths, getYear, compareAsc, setDate, format } from 'date-fns'

import { dateFormat } from '../utils/format'

const styles = (theme) => ({
  filter: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    margin: theme.spacing(1),
  },
  filterField: {
    marginLeft: 14,
    marginRight: theme.spacing(1),
    width: 200,
  },
  custom: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
})

class DateFilter extends Component {
  state = {
    fromDate: null,
    toDate: null,
    custom: '', // 'MODAL' | 'ON'
  }

  getOptions = (oldestDate) => {
    const options = [
      {
        label: 'All time',
        value: { fromDate: null, toDate: null },
      },
    ]
    if (!oldestDate) {
      return options
    }
    const now = new Date()
    let currentYear = -1
    let current = setDate(new Date(now.setHours(0, 0, 0, 0)), 1)
    const start = setDate(oldestDate, 1)
    options.push({ label: 'Custom', value: 'CUSTOM' })
    while (compareAsc(start, current) <= 0) {
      const year = getYear(current)
      if (year !== currentYear) {
        currentYear = year
        options.push({
          label: `${year}`,
          value: {
            fromDate: new Date(year, 0, 1),
            toDate: new Date(year, 11, 31),
          },
        })
      }
      options.push({
        label: format(current, 'MMM yy'),
        value: { fromDate: current, toDate: setDate(addMonths(current, 1), 0) },
      })
      current = addMonths(current, -1)
    }
    return options
  }

  handleSelectChange = (option) => {
    if (option.value !== 'CUSTOM') {
      this.props.onChange(option.value)
      this.setState({ ...option.value, custom: '' })
    } else {
      this.openCustomModal()
    }
  }

  handleRange = (ranges) => {
    const selection = ranges.selection
    if (selection) {
      const dateRange = {
        fromDate: selection.startDate,
        toDate: selection.endDate,
      }
      this.props.onChange(dateRange)
      this.setState({ ...dateRange })
    }
  }

  openCustomModal = () => this.setState({ custom: 'MODAL' })

  handleCustomClose = () => this.setState({ custom: 'ON' })

  render() {
    const { classes, oldestDate } = this.props
    const { custom, fromDate, toDate } = this.state

    const options = this.getOptions(oldestDate)

    return (
      <div className={classes.filter} style={{ zIndex: 100 }}>
        <Select
          className={classes.filterField}
          isSearchable
          options={options}
          onChange={this.handleSelectChange}
          placeholder="All time"
        />
        {!!custom && (
          <div className={classes.custom} onClick={this.openCustomModal}>
            {`${dateFormat(fromDate)} - ${dateFormat(toDate)}`}
          </div>
        )}
        <Dialog
          open={this.state.custom === 'MODAL'}
          maxWidth="md"
          onClose={this.handleCustomClose}
          aria-labelledby="custom-range"
        >
          <DialogTitle id="custom-range">Select a custom range</DialogTitle>
          <DialogContent>
            <DateRangePicker
              ranges={[
                { startDate: fromDate, endDate: toDate, key: 'selection' },
              ]}
              onChange={this.handleRange}
              showPreview={false}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCustomClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export function isWithinRange(date, dateRange) {
  if (!dateRange) {
    return true
  }

  const { fromDate, toDate } = dateRange

  if (fromDate && compareAsc(date, fromDate) < 0) {
    return false
  }

  if (toDate && compareAsc(date, toDate) > 0) {
    return false
  }

  return true
}

export default withStyles(styles)(DateFilter)
