import React from 'react'
import ReactDOM from 'react-dom'

export class AutoSizer extends React.PureComponent {
  state = { size: null }
  animationLoopId = null

  componentDidMount() {
    this.measure()
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.animationLoopId)
  }

  measure = () => {
    const el = ReactDOM.findDOMNode(this)
    const oldSize = this.state.size
    const newSize = [el.offsetWidth, el.offsetHeight]

    if (
      oldSize == null ||
      oldSize[0] !== newSize[0] ||
      oldSize[1] !== newSize[1]
    ) {
      this.setState({ size: newSize })
    }

    this.animationLoopId = window.requestAnimationFrame(this.measure)
  }

  render() {
    return (
      <div style={{ overflow: 'hidden' }}>
        {this.state.size && this.props.children(this.state.size)}
      </div>
    )
  }
}
