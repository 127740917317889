import { parse } from 'date-fns'

export const eurFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR',
}).format

export const currencyFormat = (currency) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format

export const percentageFormat = (percentage) => `${percentage * 100}%`

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
})

const shortDateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'long',
})

export const dateFormat = (date) => (date ? dateFormatter.format(date) : '')

export const shortDateFormat = (date) =>
  date ? shortDateFormatter.format(date) : ''

export const parseDate = (dateString) =>
  dateString && parse(dateString, 'yyyy-MM-dd', new Date())
