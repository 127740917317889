import React, { useState, Fragment } from 'react'
import _ from 'lodash'
import { min as minDate } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'

import { eurFormat, currencyFormat } from '../utils/format'
import { color } from '../components/style'
import Layout from '../components/Layout'
import DateFilter, { isWithinRange } from '../components/DateFilter'
import TableView from '../components/TableView'

const columns = [
  { id: 'date', numeric: false, isDate: true, label: 'Date', pctWidth: 10 },
  { id: 'amount', numeric: true, money: true, label: 'Amount', pctWidth: 10 },
  { id: 'center', numeric: false, label: 'Center', pctWidth: 15 },
  { id: 'description', numeric: false, label: 'Description', pctWidth: 35 },
  {
    id: 'last_edited_by',
    numeric: false,
    label: 'Last modified by',
    pctWidth: 20,
  },
  {
    id: 'last_edited_at',
    numeric: false,
    isDate: true,
    label: 'Last modified at',
    pctWidth: 10,
  },
]

const useStyles = makeStyles(() => ({
  wrapper: {
    marginLeft: '16px',
  },
  balance: {
    borderBottom: '1px solid #ccc',
    paddingBottom: '2px',
    marginBottom: '2px',
  },
  title: {
    marginRight: '8px',
  },
  number: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  note: {
    fontSize: '14px',
  },
  splitMessage: {
    paddingTop: '4px',
  },
}))

const getTextColor = (amount) => {
  return amount >= 5e-3
    ? 'positive'
    : amount <= -5e-3
    ? 'negative'
    : 'defaultText'
}

export const renderStatusbar = (selectedRows) => {
  const selectedAmounts = selectedRows.map((v) => v.amount)

  const outFlow = _.sum(selectedAmounts.filter((v) => v < 0))
  const formattedOutFlow = outFlow !== 0 && outFlow * -1

  const inFlow = _.sum(selectedAmounts.filter((v) => v > 0))

  const amount = inFlow + outFlow

  return (
    <Fragment>
      Selected {selectedAmounts.length} items of total value&nbsp;
      <span style={{ color: color[getTextColor(amount)], fontWeight: 'bold' }}>
        {eurFormat(amount)}
      </span>{' '}
      =&nbsp;
      <span style={{ color: color.positive }}>{eurFormat(inFlow)}</span>
      &nbsp;−&nbsp;
      <span style={{ color: color.negative }}>
        {eurFormat(formattedOutFlow)}
      </span>
    </Fragment>
  )
}

export function Balance({ filteredData, vlTechSplit, showNote = true }) {
  const classes = useStyles()

  const balance = _.sum(filteredData.map((v) => v.amount))

  const totalAmountPaymentCurrency = _.sum(
    filteredData.map((v) => v.amountPaymentCurrency),
  )
  // we know for sure that all liabilities will have the same payment currency, namely the most recent one
  const paymentCurrency = filteredData[0]?.paymentCurrency || 'EUR'

  const bothVlTech =
    vlTechSplit?.entity === 'vacuumlabs technology s.r.o.' &&
    vlTechSplit?.currency === vlTechSplit?.paymentCurrency

  const vlTechAmount =
    totalAmountPaymentCurrency <= 0 ? 0 : vlTechSplit?.vlTechSplit
  const vlTechAmountPaymentCurrency =
    totalAmountPaymentCurrency <= 0 ? 0 : vlTechSplit?.amountPaymentCurrency

  const billingAmount = totalAmountPaymentCurrency - vlTechAmountPaymentCurrency

  const formattedBalance = eurFormat(balance)

  const balanceNote =
    balance >= 5e-3
      ? '(Vacuumlabs owes you)'
      : balance <= -5e-3
      ? '(You owe Vacuumlabs)'
      : null

  return (
    <div className={classes.wrapper}>
      <div className={classes.balance}>
        <span className={classes.title}>Outstanding Balance:</span>
        <span
          className={classes.number}
          style={{ color: color[getTextColor(balance)] }}
        >
          {formattedBalance}
          {paymentCurrency !== 'EUR' &&
            ` (${currencyFormat(paymentCurrency)(totalAmountPaymentCurrency)})`}
        </span>
      </div>
      {showNote && balanceNote && (
        <span className={classes.note}>{balanceNote}</span>
      )}
      {vlTechSplit?.jiraId && (
        <div className={classes.splitMessage}>
          {!bothVlTech ? (
            <div>
              <div>
                <span>vacuumlabs technology s.r.o. invoicing amount: </span>
                <span style={{ fontWeight: 'bold' }}>
                  {currencyFormat(vlTechSplit.currency)(vlTechAmount)}
                </span>
              </div>

              <div>
                <span>{vlTechSplit.entity} invoicing amount: </span>
                <span style={{ fontWeight: 'bold' }}>
                  {currencyFormat(vlTechSplit.paymentCurrency)(billingAmount)}
                </span>
              </div>
            </div>
          ) : (
            <div>
              <span>vacuumlabs technology s.r.o. invoicing amount: </span>
              <span style={{ fontWeight: 'bold' }}>
                {currencyFormat(vlTechSplit.currency)(
                  totalAmountPaymentCurrency,
                )}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default function PayrollPage({ data }) {
  const [dateRange, setDateRange] = useState(null)

  const filteredData = data.payroll.filter((item) =>
    isWithinRange(item.date, dateRange),
  )

  const oldestDate = minDate(data.payroll.map((item) => item.date))

  return (
    <Layout
      header={
        <Fragment>
          <Balance filteredData={filteredData} vlTechSplit={data.vlTechSplit} />
          <DateFilter oldestDate={oldestDate} onChange={setDateRange} />
        </Fragment>
      }
    >
      <TableView
        data={filteredData}
        columns={columns}
        renderStatusbar={renderStatusbar}
      />
    </Layout>
  )
}
