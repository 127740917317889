import React from 'react'
import { NavLink } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Avatar from '@material-ui/core/Avatar'
import AccountCircle from '@material-ui/icons/AccountCircle'
import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Logo from '../assets/logo.svg'

import c from '../config.js'

const useStyles = makeStyles((theme) => ({
  link: {
    color: 'black',
    margin: '0px 1em',
    fontSize: '16px',
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  active: {
    color: theme.palette.primary.main,
    margin: '0px 1em',
    fontSize: '16px',
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  navigation: {
    flexGrow: 1,
    marginLeft: '13px',
    marginTop: '3px',
  },
  item: {
    marginLeft: '1em',
  },
  logoContainer: {
    display: 'flex',
    paddingRight: '30px',
    borderRight: '1px solid #ccc',
  },
  deprecatedAppMessage: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
    textDecoration: 'none',
  },
  warningIcon: {
    color: 'orange',
    marginRight: '5px',
    alignItems: 'center',
  },
}))

export default function Navigation({ user }) {
  const classes = useStyles()

  if (!user) {
    return null
  }

  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <div className={classes.logoContainer}>
          <img src={Logo} alt="Logo" width="170" />
        </div>

        <div className={classes.navigation}>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? classes.active : classes.link
            }
          >
            Payroll
          </NavLink>
          {user.hasWincentData && (
            <NavLink
              to="/wincent"
              className={({ isActive }) =>
                isActive ? classes.active : classes.link
              }
            >
              Wincent
            </NavLink>
          )}
          <NavLink
            to="/shares"
            className={({ isActive }) =>
              isActive ? classes.active : classes.link
            }
          >
            Shares
          </NavLink>
        </div>

        <div className={classes.deprecatedAppMessage}>
          <WarningIcon className={classes.warningIcon} />
          This application is no longer used. Visit the new&nbsp;
          <a href="https://mypay.vacuumlabs.com/">payroll app</a>.
        </div>

        <Typography
          variant="subtitle1"
          color="inherit"
          className={classes.item}
        >
          {user.name}
        </Typography>

        {user.avatarUrl ? (
          <Avatar
            alt={user.name}
            src={user.avatarUrl}
            className={classes.item}
          />
        ) : (
          <Avatar className={classes.item}>
            <AccountCircle />
          </Avatar>
        )}

        <Button
          className={classes.item}
          variant="outlined"
          color="inherit"
          href={`${c.serverUrl}auth/logout?redirect=${c.clientUrl}`}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  )
}
