import React from 'react'

class ShortcutHelp extends React.Component {
  render() {
    return (
      <dl className="hide-mobile">
        <dt>
          <kbd>Space</kbd>
        </dt>{' '}
        <dd>select/deselect row</dd>
        <dt>
          <kbd>Ctrl/⌘ + A</kbd>
        </dt>{' '}
        <dd>select all</dd>
        <dt>
          <kbd>Esc</kbd>
        </dt>{' '}
        <dd>deselect all</dd>
        <dt>
          <kbd>Arrow ↑ ↓</kbd>
        </dt>{' '}
        <dd>move cursor</dd>
        <dt>
          <kbd>Shift + Arrow ↑ ↓</kbd>
        </dt>{' '}
        <dd>select rows while moving cursor</dd>
      </dl>
    )
  }
}

export default ShortcutHelp
