import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'

import Spinner from 'react-spinkit'
import Navigation from './components/Navigation'
import Error from './components/Error'
import LoginPage from './pages/LoginPage'
import PayrollPage from './pages/PayrollPage'
import WincentPage from './pages/WincentPage'
import SharesPage from './pages/SharesPage'
import Fetch from './components/Fetch'

import favicon16 from './assets/favicon-16x16.png'
import favicon32 from './assets/favicon-32x32.png'
import './assets/fonts/MyFontsWebfontsKit.css'

import { useResource } from './utils/hooks'
import {
  userRequest,
  payrollRequest,
  wincentRequest,
  sharesRequest,
} from './utils/api'

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
})

export const GlobalStateContext = React.createContext({})

function App() {
  const { data: user, loading, error } = useResource(userRequest)

  let page
  if (!user) {
    page = <LoginPage />
  } else if (error) {
    page = <Error message={error} />
  } else {
    page = (
      <Routes>
        <Route
          path="/"
          element={<Fetch request={payrollRequest} page={PayrollPage} />}
        />
        <Route
          path="/wincent"
          element={<Fetch request={wincentRequest} page={WincentPage} />}
        />
        <Route
          path="/shares"
          element={<Fetch request={sharesRequest} page={SharesPage} />}
        />
        <Route
          path="*"
          element={<Error message={'Page not found'} />}
          status={404}
        />
      </Routes>
    )
  }

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <div
          style={{
            display: 'grid',
            height: '100%',
            gridTemplateRows: '64px 1fr',
          }}
        >
          <Helmet>
            <link rel="icon" type="image/png" href={favicon16} size="16x16" />
            <link rel="icon" type="image/png" href={favicon32} size="32x32" />
          </Helmet>

          {loading ? (
            <Spinner
              fadeIn="quarter"
              name="line-scale-pulse-out"
              color="#f07"
              style={{ margin: 'auto' }}
            />
          ) : (
            <>
              <Navigation user={user} />
              {page}
            </>
          )}
        </div>
      </BrowserRouter>
    </MuiThemeProvider>
  )
}

export default function AppWithGlobalState() {
  const [globalState, setGlobalState] = useState({})
  const context = { globalState, setGlobalState }

  return (
    <GlobalStateContext.Provider value={context}>
      <App />
    </GlobalStateContext.Provider>
  )
}
