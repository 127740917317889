import React, { useState, Fragment } from 'react'
import _ from 'lodash'
import { min as minDate } from 'date-fns'

import { color } from '../components/style'
import Layout from '../components/Layout'
import DateFilter, { isWithinRange } from '../components/DateFilter'
import TableView from '../components/TableView'
import SharesBalancesTable from '../components/SharesBalancesTable'

const columns = [
  {
    id: 'issue_date',
    numeric: false,
    isDate: true,
    label: 'Issued',
    pctWidth: 10,
  },
  { id: 'amount', numeric: true, label: 'Amount', pctWidth: 5 },
  {
    id: 'vesting_date',
    numeric: false,
    isDate: true,
    label: 'Vesting',
    pctWidth: 5,
  },
  { id: 'vested', numeric: false, isDate: false, label: 'Vested', pctWidth: 5 },
  { id: 'klass', numeric: false, label: 'Class', pctWidth: 5 },
  { id: 'note', numeric: false, label: 'Note', pctWidth: 35 },
  {
    id: 'buyback_option',
    numeric: false,
    label: 'Buyback option',
    pctWidth: 5,
  },
  { id: 'type', numeric: false, label: 'Type', pctWidth: 5 },
  {
    id: 'purchase_price',
    money: true,
    numeric: true,
    label: 'Purchase price',
    pctWidth: 5,
  },
  {
    id: 'discount',
    isPercentage: true,
    numeric: true,
    label: 'Discount',
    pctWidth: 5,
  },
  { id: 'share_entity', numeric: false, label: 'Share entity', pctWidth: 5 },
  { id: 'created_by', numeric: false, label: 'Created by', pctWidth: 5 },
  {
    id: 'created_at',
    numeric: false,
    isDate: true,
    label: 'Created at',
    pctWidth: 5,
  },
]

const renderStatusbar = (selectedRows) => {
  const selectedAmounts = selectedRows.map((v) => v.amount)

  const outFlow = _.sum(selectedAmounts.filter((v) => v < 0))
  const inFlow = _.sum(selectedAmounts.filter((v) => v > 0))

  return (
    <Fragment>
      Selected {selectedAmounts.length} lines of total amount&nbsp;
      <span
        style={{
          color: inFlow + outFlow > 0 ? color.positive : color.negative,
        }}
      >
        {inFlow + outFlow}
      </span>{' '}
      =&nbsp;
      <span style={{ color: color.positive }}>{inFlow}</span>&nbsp;−&nbsp;
      <span style={{ color: color.negative }}>{-outFlow}</span>
    </Fragment>
  )
}

/**
 * @param {{data: SharesData[]}} param
 */
export default function SharesPage({ data }) {
  const [dateRange, setDateRange] = useState(null)

  const filteredData = data.filter((item) =>
    isWithinRange(item.issue_date, dateRange),
  )

  const oldestDate = minDate(data.map((item) => item.issue_date))

  return (
    <Layout
      header={
        <Fragment>
          <SharesBalancesTable filteredData={filteredData} />
          <DateFilter oldestDate={oldestDate} onChange={setDateRange} />
        </Fragment>
      }
    >
      <TableView
        data={filteredData}
        columns={columns}
        renderStatusbar={renderStatusbar}
      />
    </Layout>
  )
}
