import { useContext, useEffect } from 'react'
import useFetch from 'use-http'
import _ from 'lodash'

import { GlobalStateContext } from '../App'

export const useResource = ({ name, url, postProcessing = _.identity }) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext)
  const cache = globalState[name]
  const { get, response, loading, error } = useFetch(url)

  useEffect(() => {
    const getData = async () => {
      const data = await get()
      if (response.ok) {
        const processedData = postProcessing(data)
        setGlobalState((state) => ({ ...state, [name]: processedData }))
      }
    }
    if (!cache && !loading) {
      getData()
    }
    // eslint-disable-next-line
  }, [name, url, postProcessing])

  return { data: cache, loading, error }
}
