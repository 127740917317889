import React from 'react'
import _ from 'lodash'
import { GridCanvas } from './GridCanvas'
import { AutoSizer } from './AutoSizer'
import { color, font, cellHeight, width, txtPadding } from './style'
import { eurFormat, dateFormat, percentageFormat } from '../utils/format'
import ShortcutsHelp from './ShortcutsHelp'

const SORT_ORDER_ARROW = {
  asc: '▲ ',
  desc: '▼ ',
  default: '— ',
}

class Table extends React.Component {
  state = {
    cursor: 1,
    selected: {},
  }

  tableRef = React.createRef()
  firstFocus = true

  toggleRow = (n) => {
    const { selected } = this.state
    const id = this.props.data[n - 1].id
    selected[id] = !selected[id]
    this.setState({})
  }

  toggleRange(i, j) {
    ;[i, j] = [i, j].sort((a, b) => a - b)
    _.range(i, j + 1).forEach(this.toggleRow)
  }

  onKeyDown = (e) => {
    const { cursor } = this.state
    const { data } = this.props

    const selectCurrent = () => this.toggleRow(cursor)

    // Select All
    if (e.key.toLowerCase() === 'a' && (e.metaKey || e.ctrlKey)) {
      e.preventDefault()
      this.setState({ selected: _.fromPairs(data.map((v) => [v.id, true])) })
    }

    if (e.key === 'Escape') this.setState({ selected: {} })

    if (e.key === ' ') selectCurrent()

    const arrows = {
      ArrowDown: 1,
      ArrowUp: -1,
    }

    const arrow = arrows[e.key]
    if (arrow != null) {
      if (e.shiftKey) selectCurrent()

      const nCursor = _.clamp(cursor + arrow, 1, data.length)
      this.setState({ cursor: nCursor, scrollTo: [0, nCursor] })
    }
  }

  onClick = (e) => {
    const { sortByColumn } = this.props
    const { cursor } = this.state
    const mousePos = this.mousePos

    if (mousePos == null) return

    if (mousePos[1] === 0) {
      if (sortByColumn) sortByColumn(mousePos[0])
      return
    }

    if (e.shiftKey) this.toggleRange(cursor, mousePos[1])
    else if (e.metaKey || e.ctrlKey) this.toggleRow(mousePos[1])

    this.setState({ cursor: mousePos[1] })
  }

  onCopy = (e) => {
    const { cursor, selected } = this.state
    const { columns } = this.props

    let selectedRows = Object.keys(selected)
      .filter((rowId) => selected[rowId])
      .map((rowId) => Number(rowId) + 1)

    if (selectedRows.length === 0) {
      selectedRows = [cursor]
    }

    const newClipboardData = selectedRows
      .map((rowId) =>
        Object.keys(columns)
          .map((colId) => this.getCell(colId, rowId)[0])
          .join('\t'),
      )
      .join('\n')

    e.clipboardData.setData('text/plain', newClipboardData)
    e.preventDefault()
  }

  onScroll = (e) => {
    this.setState({ scrollTo: null })
  }

  static getDerivedStateFromProps(props, state) {
    const { data } = props
    let { cursor, scrollTo } = state
    cursor = _.clamp(cursor, 1, data.length)
    scrollTo = scrollTo && [0, _.clamp(scrollTo[1], 1, data.length)]
    return { cursor, scrollTo }
  }

  componentDidUpdate() {
    this.tableRef.current && this.firstFocus && this.tableRef.current.focus()
    this.firstFocus = false
  }

  getColumnWidths = (fullWidth) => {
    return this.props.columns.map((c) => (fullWidth * c.pctWidth) / 100)
  }

  getCell = (c, r) => {
    const { data, columns, sortingOptions, sortByColumn } = this.props

    const column = columns[c]
    if (r === 0) {
      return [
        sortingOptions && sortingOptions.columnIndex === c
          ? `${SORT_ORDER_ARROW[sortingOptions.sortOrder]}${column.label}`
          : `${sortByColumn ? SORT_ORDER_ARROW.default : ''}${column.label}`,
        {
          color: color.headerText,
          backgroundColor: color.headerBg,
          bold: true,
        },
        [],
      ]
    }

    const row = data[r - 1]

    const bg = this.state.selected[row.id]
      ? { backgroundColor: color.selectedRow }
      : {}

    if (column.numeric) {
      bg.align = 'right'
    }

    const value = row[column.id]

    if (column.isDate) {
      return [dateFormat(value), { ...bg }, []]
    }

    if (column.isPercentage) {
      return [percentageFormat(value), { ...bg }, []]
    }

    if (column.money) {
      return [
        eurFormat(value),
        {
          ...bg,
          color: value < 0 ? color.negative : color.positive,
          align: 'right',
        },
        [],
      ]
    }

    return [value, { ...bg }, []]
  }

  render() {
    const { data, columns, renderStatusbar } = this.props

    const selectedRows = data.filter((v) => this.state.selected[v.id])

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateRows: '24px 1fr',
          padding: '0 24px 24px',
          gridGap: '15px',
        }}
      >
        <div>{renderStatusbar(selectedRows)}</div>
        <AutoSizer>
          {(size) => (
            <div
              style={{ position: 'relative' }}
              tabIndex="0"
              ref={this.tableRef}
              onKeyDown={this.onKeyDown}
              onClick={this.onClick}
              onCopy={this.onCopy}
            >
              <GridCanvas
                getCell={this.getCell}
                rows={data.length + 1}
                cols={columns.length}
                colWidths={this.getColumnWidths}
                size={size}
                selection={[0, this.state.cursor, columns.length, 1]}
                activeCell={[0, 0]}
                scrollTo={this.state.scrollTo}
                onScroll={this.onScroll}
                onPaint={({ mousePos }) => {
                  this.mousePos = mousePos
                }}
                style={{ color, font, cellHeight, width, txtPadding }}
              />
            </div>
          )}
        </AutoSizer>
        <ShortcutsHelp />
      </div>
    )
  }
}

export default Table
