import React, { useState, Fragment } from 'react'
import { min as minDate } from 'date-fns'

import Layout from '../components/Layout'
import DateFilter, { isWithinRange } from '../components/DateFilter'
import TableView from '../components/TableView'
import { renderStatusbar, Balance } from './PayrollPage'

const columns = [
  { id: 'date', numeric: false, isDate: true, label: 'Date', pctWidth: 10 },
  { id: 'amount', numeric: true, money: true, label: 'Amount', pctWidth: 10 },
  { id: 'description', numeric: false, label: 'Description', pctWidth: 50 },
  {
    id: 'last_edited_by',
    numeric: false,
    label: 'Last modified by',
    pctWidth: 20,
  },
  {
    id: 'last_edited_at',
    numeric: false,
    isDate: true,
    label: 'Last modified at',
    pctWidth: 10,
  },
]

export default function WincentPage({ data }) {
  const [dateRange, setDateRange] = useState(null)

  const filteredData = data.payroll.filter((item) =>
    isWithinRange(item.date, dateRange),
  )

  const oldestDate = minDate(data.payroll.map((item) => item.date))

  return (
    <Layout
      header={
        <Fragment>
          <Balance filteredData={filteredData} showNote={false} />
          <DateFilter oldestDate={oldestDate} onChange={setDateRange} />
        </Fragment>
      }
    >
      <TableView
        data={filteredData}
        columns={columns}
        renderStatusbar={renderStatusbar}
      />
    </Layout>
  )
}
