const cellHeight = 20

export const style = {
  color: {
    grid: '#e3ecf2',
    gridBg: '#f3f3f3',
    gridBorder: '#c0c0c0',
    defaultBg: 'white',
    defaultText: '#212d3b',
    frozen: 'aliceblue',
    selectionBorder: 'white',
    selectionOverlay: 'rgba(231, 32, 118, 0.2)',
    selectionOutline: '#e72076',
  },
  font: {
    normal: '12px Eina01',
    mono: '12px Courier',
  },
  cellHeight,
  txtPadding: Math.round(0.25 * cellHeight),
  // line widths
  width: {
    grid: 1,
    gridBorder: 2,
    frozen: 4,
    outline: 2,
    padding: 1,
    mark: 7,
  },
}
