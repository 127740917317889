import '../typedefs/shares-data.typedef'
import { parseDate } from './format'
import { isFuture } from 'date-fns'

export const processPayrollData = (data) => {
  return {
    payroll: data.payroll
      .map((item, id) => {
        item.id = id
        item.last_edited_at = parseDate(item.last_edited_at)
        item.date = parseDate(item.date)
        return item
      })
      .sort((i1, i2) => i1.date.getTime() - i2.date.getTime()),
    vlTechSplit: data.vlTechSplit,
  }
}
/**
 * @param {RawSharesData[]} data
 * @returns {SharesData[]}
 */
export const processSharesData = (data) =>
  data
    .map((item, id) => {
      item.id = id
      item.created_at = parseDate(item.created_at)
      item.issue_date = parseDate(item.issue_date)
      item.vesting_date = parseDate(item.vesting_date)
      item.vested = isFuture(item.vesting_date) ? 'No' : 'Yes'
      return item
    })
    .sort((i1, i2) => i1.issue_date.getTime() - i2.issue_date.getTime())

export const userRequest = { name: 'user', url: '/api/user' }
export const payrollRequest = {
  name: 'payroll',
  url: '/api/payroll',
  postProcessing: processPayrollData,
}
export const wincentRequest = {
  name: 'wincent',
  url: '/api/wincent',
  postProcessing: processPayrollData,
}
export const sharesRequest = {
  name: 'shares',
  url: '/api/shares',
  postProcessing: processSharesData,
}
