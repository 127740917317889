import React from 'react'

import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  main: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  },
  table: {
    flexGrow: '1',
  },
  container: {
    display: 'block',
    margin: theme.spacing(1),
  },
})

function Layout({ classes, header, children }) {
  return (
    <main className={classes.main}>
      <div
        className={classes.container}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {header}
      </div>
      {children}
    </main>
  )
}

export default withStyles(styles)(Layout)
