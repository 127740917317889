import '../typedefs/shares-data.typedef'
import React from 'react'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

import SharesBalance from './SharesBalance'

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: '1rem',
  },
  entityHeader: {
    borderBottom: '1px solid #ccc',
  },
  numberHeader: {
    textAlign: 'right',
    paddingRight: '1rem',
    borderBottom: '1px solid #ccc',
  },
  entityName: {
    paddingRight: '2rem',
    fontSize: 'medium',
    fontWeight: 'bold',
    color: '#616161',
  },
}))

/**
 * @param {{filteredData: SharesData[]}} param
 */
export default function SharesBalancesTable({ filteredData }) {
  const classes = useStyles()

  const sharesDataByEntity = _.groupBy(filteredData, 'share_entity')
  const sortedSharesDataEntries = _.orderBy(
    Object.entries(sharesDataByEntity),
    [([name]) => name.toLowerCase()],
  )

  return (
    <div className={classes.container}>
      {!filteredData.length ? (
        <span>No shares in the selected period</span>
      ) : (
        <table>
          <thead>
            <tr>
              <td className={classes.entityHeader}>Entity</td>
              <td className={classes.numberHeader}>Vested</td>
              <td className={classes.numberHeader}>Unvested</td>
              <td className={classes.numberHeader}>Total</td>
            </tr>
          </thead>
          <tbody>
            {sortedSharesDataEntries.map(([name, data]) => (
              <tr key={name}>
                <td className={classes.entityName}>{name}</td>
                <SharesBalance filteredData={data} />
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}
