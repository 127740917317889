import '../typedefs/shares-data.typedef'
import React, { Fragment } from 'react'
import _ from 'lodash'
import { isFuture } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  balance: {
    paddingLeft: '2rem',
    textAlign: 'right',
  },
  number: {
    fontSize: 'medium',
    fontWeight: 'bold',
  },
}))

/**
 * @param {{filteredData: SharesData[]}} param
 */
export default function SharesBalance({ filteredData }) {
  const classes = useStyles()

  const [unvestedShares, vestedShares] = _.partition(filteredData, (item) =>
    isFuture(item.vesting_date),
  )

  const vested = _.sumBy(vestedShares, 'amount')
  const unvested = _.sumBy(unvestedShares, 'amount')
  const total = vested + unvested

  const balances = { vested, unvested, total }

  return (
    <Fragment>
      {Object.entries(balances).map(([key, balance]) => (
        <td className={classes.balance} key={key}>
          <dt style={{ display: 'none' }}>{_.capitalize(key)}:</dt>
          <dd>
            <span className={classes.number}>{balance}</span> shares
          </dd>
        </td>
      ))}
    </Fragment>
  )
}
