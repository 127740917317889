import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Spinner from 'react-spinkit'

import Logo from '../assets/logo.svg'
import c from '../config.js'

const styles = (theme) => ({
  container: {
    paddingTop: '10em',
  },
  item: {
    textAlign: 'center',
  },
  loader: {
    height: '22px',
    '& > div': {
      height: '22px',
    },
  },
})

class LoginPage extends Component {
  state = {
    loading: false,
  }

  render() {
    const { classes } = this.props
    const { loading } = this.state

    return (
      <Grid
        container
        direction="column"
        spacing={8}
        classes={{ container: classes.container }}
      >
        <Grid item xs={12} classes={{ item: classes.item }}>
          <img src={Logo} alt="Logo" width="250" />
        </Grid>

        <Grid item xs={12} classes={{ item: classes.item }}>
          <Button
            color="primary"
            variant="contained"
            href={`${c.serverUrl}auth/login?redirect=${c.clientUrl}`}
            onClick={() => this.setState({ loading: true })}
          >
            {loading ? (
              <Spinner
                fadeIn="quarter"
                name="line-scale-pulse-out"
                color="#fff"
                className={classes.loader}
              />
            ) : (
              'Login'
            )}
          </Button>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(LoginPage)
