import React from 'react'
import Spinner from 'react-spinkit'
import { useResource } from '../utils/hooks'

import Error from './Error'

export default function Fetch({ page: Page, request }) {
  const { data, loading, error } = useResource(request)

  if (error) {
    return <Error message={error} />
  } else if (loading || !data) {
    return (
      <Spinner
        fadeIn="quarter"
        name="line-scale-pulse-out"
        color="#f07"
        style={{ margin: 'auto' }}
      />
    )
  } else {
    return <Page data={data} />
  }
}
